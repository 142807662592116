export default class Tabs {
  constructor(options) {
    this.elem = document.querySelector(options.elem) || null;
    this.open = options.open || 0;
    this.tabsNavSelector = '[data-tabs-nav]';
    this.tabsButtonSelector = '[data-tabs-button]';
    this.tabsContentSelector = '[data-tabs-content]';
    this.tabsItemSelector = '[data-tabs-item]';
    this.tabsNum = 0;
    this.tabsButtons = [];
    this.tabsItems = [];
    this.mediumDevice = window.matchMedia('(min-width: 1024px) and (max-width: 1199px)');
    this.largeDevice = window.matchMedia('(min-width: 1200px)');
  }

  init() {
    if (!this.elem) {
      return;
    }

    this.tabsButtons = [...this.elem.querySelectorAll(this.tabsButtonSelector)];
    this.tabsNum = this.tabsButtons.length;

    this.tabsItems = [...this.elem.querySelectorAll(this.tabsItemSelector)];
    this.elem.addEventListener('click', this.tabsHandler.bind(this));

    const init = this.checkTab(this.open);

    this.tabsButtons.forEach((el, index) => {
      el.setAttribute('data-index', index);

      if (index === init) {
        this.openTab(index);
      }
    });

    const handleMediumChange = () => this.handleDeviceChange.call(this, this.mediumDevice);
    const handleLargeChange = () => this.handleDeviceChange.call(this, this.largeDevice);

    this.mediumDevice.addEventListener('change', handleMediumChange);
    this.largeDevice.addEventListener('change', handleLargeChange);

    handleMediumChange();
    handleLargeChange();
  }

  handleDeviceChange(e) {
    if (e.matches && this.tabsItems.length) {
      const tabsItemsHeights = this.tabsItems.map((el) => el.clientHeight);
      const maxTabHeight = Math.max(...tabsItemsHeights);
      this.elem.style.setProperty('--min-height', maxTabHeight);
    }
  }

  tabsHandler(e) {
    if (!e.target.hasAttribute('data-tabs-button')) {
      return;
    }

    e.preventDefault();
    const index = e.target.getAttribute('data-index') || 0;
    this.openTab.call(this, index);
  }

  reset() {
    this.tabsButtons.forEach((el) => el.classList.remove('active'));
    this.tabsItems.forEach((el) => el.classList.remove('active'));
  }

  checkTab(n) {
    return (n < 0 || Number.isNaN(n) || n > this.tabsNum) ? 0 : n;
  }

  openTab(n) {
    this.reset();

    const index = this.checkTab(n);
    this.tabsButtons[index].classList.add('active');
    this.tabsItems[index].classList.add('active');
  }
}
