import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersNewspaper = document.querySelectorAll('[data-swiper-company-newspaper]');
const sliderNewspaperOptions = {
  loop          : false,
  slidesPerView : 1.5,
  spaceBetween  : 16,
  breakpoints   : {
    1024: {
      slidesPerView : 3,
      spaceBetween  : 32
    }
  }
};

(function initNewspaperSlider() {
  slidersNewspaper.forEach((slider) => new Swiper(slider, sliderNewspaperOptions));
}());
