import Tabs from '../../utils/tabs';

const assetsTabs = document.querySelector('[data-assets-tabs]');

if (assetsTabs) {
  const tabs = new Tabs({
    elem: '[data-assets-tabs]'
  });
  tabs.init();
}
