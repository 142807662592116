import $ from 'jquery';
import 'select2';

class LangSelect {
  constructor(langItemsSelector) {
    this.selector = langItemsSelector;
    this.langSelectItems = [...document.querySelectorAll(this.selector)];
    // this.listAttrName = '[data-lang-select-list]';
    this.selectOpenState = false;
  }

  init() {
    this.langSelectItems.forEach((selectWrapper) => this.initSelect(selectWrapper));
  }

  initSelect(selectWrapper) {
    const selectEl = selectWrapper.querySelector('[data-lang-select-list]');
    const initializedSelect = $(selectEl)
      .select2({
        dropdownParent          : $(selectWrapper),
        minimumResultsForSearch : -1,
        dropdownAutoWidth       : true,
        width                   : '100%',
        dropdownCssClass        : 'language-select__dropdown'
      })
      .on('select2:select', this.selectHandler);

    this.openHandler.bind(this);
    this.closingHandler.bind(this);

    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    if (supportsTouch) {
      selectWrapper.addEventListener('click', () => this.openHandler(selectEl, initializedSelect));
    } else {
      selectWrapper.addEventListener('mouseenter', () => this.openHandler(selectEl, initializedSelect));
    }

    selectWrapper.addEventListener('mouseleave', () => this.closingHandler(selectWrapper, initializedSelect));
  }

  selectHandler(e) {
    const value = $(e.currentTarget).val();
    window.location.href = value;
  }

  closingHandler(el, initializedSelect) {
    if (!this.selectOpenState) {
      return;
    }

    this.selectOpenState = false;

    const selectResultsElements = el.querySelector('.select2-results');

    if (selectResultsElements) {
      selectResultsElements.classList.remove('select2-results--visible');
    }

    initializedSelect.select2('close');
  }

  openHandler(el, initializedSelect) {
    if (this.selectOpenState) {
      return;
    }

    this.selectOpenState = true;

    initializedSelect.select2('open');

    const selectResultsElements = el.parentNode.parentNode.querySelector('.select2-results');

    selectResultsElements.classList.add('select2-results--visible');
  }
}

const langSelect = new LangSelect('[data-lang-select]');
langSelect.init();
