export const removeClassIncludes = (el, value) => {
  if (!el || !value) {
    return;
  }
  const { classList } = el;
  [...classList].forEach((i) => {
    if (i.includes(value)) {
      el.classList.remove(i);
    }
  });
};
