import { removeClassIncludes } from '../../utils/remove-class-includes';

class Search {
  constructor() {
    this.siteHeader = document.querySelector('[data-site-header=""]');
    this.searchDropdown = document.querySelector('[data-main-search="dropdown"]');
    this.searchTriggerItems = [...document.querySelectorAll('[data-main-search="trigger"]')];
    this.searchClearButton = this.searchDropdown.querySelector('[data-search="clear-button"]');
    this.desktopMQ = window.matchMedia('(min-width: 1200px)');
    this.isMobileOrTabletInit = false;
  }

  init() {
    this.mediaQueryHandler(this.desktopMQ);
    this.bindHandlers();
  }

  bindHandlers() {
    this.desktopMQ.addEventListener('change', this.mediaQueryHandler.bind(this));
    this.searchTriggerItems.forEach((el) => el.addEventListener('click', this.searchTriggerHandler.bind(this)));
  }

  isSearchOpen() {
    return this.siteHeader.classList.value.includes('--search-open');
  }

  showDropdown(triggerType) {
    if (triggerType !== 'large') {
      document.documentElement.classList.add('overflow-hidden');
      document.body.classList.add('overflow-hidden');
    }

    removeClassIncludes(this.siteHeader, '-open');
    this.siteHeader.classList.add('header--search-open');
    this.searchDropdown.classList.add('search-dropdown--open');
  }

  hideDropdown(triggerType) {
    if (triggerType !== 'large') {
      document.documentElement.classList.remove('overflow-hidden');
      document.body.classList.remove('overflow-hidden');
    }
    this.siteHeader.classList.remove('header--search-open');
    this.searchDropdown.classList.remove('search-dropdown--open');
    this.searchClearButton.click();
  }

  searchTriggerHandler(e) {
    const { currentTarget } = e;
    const triggerType = currentTarget.getAttribute('data-main-search-trigger-type');

    if (this.isSearchOpen()) {
      this.hideDropdown(triggerType);
      return;
    }

    this.showDropdown(triggerType);
  }

  mediaQueryHandler(e) {
    if (e.matches && this.isMobileOrTabletInit && this.isSearchOpen()) {
      document.documentElement.classList.remove('overflow-hidden');
      document.body.classList.remove('overflow-hidden');
      return;
    }

    this.isMobileOrTabletInit = true;
    if (this.isSearchOpen()) {
      document.documentElement.classList.add('overflow-hidden');
      document.body.classList.add('overflow-hidden');
    }
  }
}

const search = new Search();
search.init();
