const { ymaps } = window;

if (document.querySelector('#map-contacts')) {
  ymaps.ready(init);
}

function init() {
  // Создание карты.
  const myMap = new ymaps.Map('map-contacts', {
    center   : [55.73180331529982, 37.62718090189645],
    zoom     : 15,
    controls : []
  });
  // Создаём макет содержимого.
  const MyIconContentLayout = ymaps.templateLayoutFactory.createClass('<div class="map-contacts__icon"></div>');
  const myPlacemarkWithContent = new ymaps.Placemark([55.73180331529982, 37.62718090189645], {
    iconContent: '12'
  }, {
    iconLayout        : 'default#imageWithContent',
    iconImageHref     : '',
    iconContentLayout : MyIconContentLayout,
    iconImageSize     : [170, 98],
    iconImageOffset   : [-100, -98],
    iconContentOffset : [15, 15]
  });

  myMap.geoObjects
    .add(myPlacemarkWithContent);
}
