import { removeClassIncludes } from '../../utils/remove-class-includes';

class MobileMenu {
  constructor() {
    this.siteHeader = document.querySelector('[data-site-header=""]');
    this.menu = document.querySelector('[data-mobile-menu="main"]');
    this.menuTriggerItems = [...document.querySelectorAll('[data-mobile-menu="trigger"]')];
    this.menuExpandItems = [...this.menu.querySelectorAll('[data-mobile-menu="expand"]')];
    this.menuPrevItems = [...this.menu.querySelectorAll('[data-mobile-menu="prev"]')];
    this.menuDropdownItems = [...this.menu.querySelectorAll('[data-mobile-menu="dropdown"]')];
    this.menuWrapper = document.querySelector('[data-mobile-menu="wrapper"]');
    this.menuDropdownWrapperItems = [...this.menu.querySelectorAll('[data-mobile-menu="dropdown-wrapper"]')];
    this.desktopMQ = window.matchMedia('(min-width: 1200px)');
    this.isMobileOrTabletInit = false;
  }

  init() {
    this.mediaQueryHandler(this.desktopMQ);
    this.bindHandlers();
  }

  bindHandlers() {
    this.desktopMQ.addEventListener('change', this.mediaQueryHandler.bind(this));
    this.menuTriggerItems.forEach((el) => el.addEventListener('click', this.menuTriggerHandler.bind(this)));
    this.menuExpandItems.forEach((el) => el.addEventListener('click', this.menuExpandHandler.bind(this)));
    this.menuPrevItems.forEach((el) => el.addEventListener('click', this.menuPrevHandler.bind(this)));
  }

  isMenuOpen() {
    return this.siteHeader.classList.value.includes('--nav-open');
  }

  isElDropdown(el) {
    return (el.hasAttribute('data-mobile-menu') && el.getAttribute('data-mobile-menu') === 'dropdown');
  }

  openMobileMenu() {
    document.documentElement.classList.add('overflow-hidden');
    document.body.classList.add('overflow-hidden');
    removeClassIncludes(this.siteHeader, '-open');
    this.siteHeader.classList.add('header--nav-open');
    this.menu.classList.add('mobile-menu--open');
  }

  closeMobileMenu() {
    document.documentElement.classList.remove('overflow-hidden');
    document.body.classList.remove('overflow-hidden');
    removeClassIncludes(this.siteHeader, '--nav-open');
    removeClassIncludes(this.menu, '--open');
    this.closeMobileMenuDropdowns();
    this.scrollDropdownWrappersTop();
    this.menuWrapper.classList.add('is-scrollable');
  }

  closeMobileMenuDropdowns() {
    this.menuDropdownItems.forEach((el) => {
      removeClassIncludes(el, '--open');
    });
  }

  menuTriggerHandler() {
    if (!this.menu) {
      return;
    }

    if (this.isMenuOpen()) {
      this.closeMobileMenu();
      return;
    }

    this.openMobileMenu();
  }

  menuExpandHandler(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const closestParentItem = currentTarget.closest('[data-mobile-menu="item-has-dropdown"]');
    const dropdownEl = closestParentItem.querySelectorAll('[data-mobile-menu="dropdown"]')[0];
    this.menuWrapper.classList.remove('is-scrollable');
    if (!this.isElDropdown(dropdownEl)) {
      return;
    }
    dropdownEl.classList.add('mobile-menu__dropdown--open');
    if (currentTarget.hasAttribute('data-mobile-menu-expand') && currentTarget.getAttribute('data-mobile-menu-expand') === 'sub') {
      const closestDropdownContent = currentTarget.closest('[data-mobile-menu="dropdown-content"]');
      const listTitle = closestDropdownContent.querySelectorAll('[data-mobile-menu="list-title"]')[0];
      const prevButtonText = dropdownEl.querySelectorAll('[data-mobile-menu-prev="text"]')[0];
      prevButtonText.innerText = listTitle.innerText;
    }
  }

  menuPrevHandler(e) {
    const { currentTarget } = e;
    const dropdownEl = currentTarget.closest('[data-mobile-menu="dropdown"]');
    if (currentTarget.hasAttribute('data-mobile-menu-prev') && currentTarget.getAttribute('data-mobile-menu-prev') === 'main') {
      this.menuWrapper.classList.add('is-scrollable');
    }
    if (this.isElDropdown(dropdownEl)) {
      removeClassIncludes(dropdownEl, '--open');
      this.scrollDropdownWrappersTop();
    }
  }

  scrollDropdownWrappersTop() {
    this.menuDropdownWrapperItems.forEach((el) => {
      const dropdownWrapperEl = el;
      dropdownWrapperEl.scrollTop = 0;
    });
  }

  mediaQueryHandler(e) {
    if (e.matches && this.isMobileOrTabletInit) {
      this.closeMobileMenu();
      return;
    }

    this.isMobileOrTabletInit = true;
  }
}

const mobileMenu = new MobileMenu();
mobileMenu.init();
