import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersVideo = document.querySelectorAll('[data-swiper-video]');
const sliderVideoOptions = {
  loop          : false,
  slidesPerView : 1.5,
  spaceBetween  : 16,
  breakpoints   : {
    1024: {
      slidesPerView : 3,
      spaceBetween  : 32
    }
  }
};

(function initVideoSlider() {
  slidersVideo.forEach((slider) => new Swiper(slider, sliderVideoOptions));
}());
