import lightGallery from 'lightgallery';

class Gallery {
  constructor(gallerySelector) {
    this.listPhoto = [];
    this.galleryEl = document.querySelector(gallerySelector);
    this.galleryGridEl = this.galleryEl.querySelector('[data-gallery="grid"]');
    this.galleryItemTemplateEl = this.galleryEl.querySelector('[data-gallery="template"]');
    this.galleryItemTemplateContent = this.galleryItemTemplateEl.content;
    this.galleryActionsEl = this.galleryEl.querySelector('[data-gallery="actions"]');
    this.galleryButtonEl = this.galleryEl.querySelector('[data-gallery="button"]');
    this.lang = document.documentElement.lang;
    this.isInit = false;
    this.currentPage = 0;
    this.pageCount = 0;
    this.refHandlerGalleryButton = null;
    this.galleryPopupPlugin = null;
  }

  async init() {
    await this.getPhotos({
      page: 1
    });
    this.isInit = true;
  }

  async getPhotos(params = {}) {
    const response = await this.fetchPhotos(params);

    if (response === null) {
      return;
    }

    if (response.errors.length) {
      this.handleResponseErrors(response);
    }

    this.render(response.data);
  }

  async fetchPhotos(objWithParams) {
    const params = new URLSearchParams({
      locale: this.lang,
      ...(objWithParams || {})
    }).toString();

    try {
      const request = await fetch(`/api/v1/photos?${params}`);
      return await request.json();
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  handleResponseErrors(response) {
    const { errors } = response;
    console.warn(errors);
  }

  render(data) {
    const { currentPage, pageCount, items } = data;
    this.currentPage = +currentPage;
    this.pageCount = +pageCount;
    this.renderItems(items);
    this.renderAction();

    items.forEach((el) => {
      this.listPhoto.push(el);
    });
    this.initGalleryPopup();
  }

  renderItems(items) {
    items.forEach((el) => {
      const item = this.galleryItemTemplateContent.cloneNode(true);
      const itemGallery = item.querySelector('.gallery-grid__item');
      itemGallery.setAttribute('data-src', el.picture.src);

      const itemImg = item.querySelector('img');
      itemImg.setAttribute('src', el.thumbnail.src);
      itemImg.setAttribute('alt', el.name);
      this.galleryGridEl.appendChild(item);
    });
  }

  renderAction() {
    if ((this.pageCount > this.currentPage) && !this.isInit) {
      this.refHandlerGalleryButton = this.handlerGalleryButton.bind(this);
      this.galleryActionsEl.classList.remove('d-none');
      this.galleryButtonEl.addEventListener('click', this.refHandlerGalleryButton);
      return;
    }

    if ((this.pageCount === this.currentPage) && this.isInit) {
      this.galleryActionsEl.classList.add('d-none');
      this.galleryButtonEl.removeEventListener('click', this.refHandlerGalleryButton);
    }
  }

  async handlerGalleryButton() {
    this.galleryButtonEl.setAttribute('disabled', '');
    await this.getPhotos({
      page: this.currentPage + 1
    });
    this.galleryButtonEl.removeAttribute('disabled');
  }

  initGalleryPopup() {
    if (this.galleryPopupPlugin) {
      this.galleryPopupPlugin.destroy();
    }

    const list = this.listPhoto.map((el) => {
      const result = {
        src     : el.picture.src,
        thumb   : el.thumbnail.src,
        subHtml : `<p class="typography-h3">${el.name}</p>`
      };
      return result;
    });

    this.galleryPopupPlugin = lightGallery(this.galleryGridEl, {
      addClass  : 'photo-gallery-popup',
      dynamic   : true,
      dynamicEl : list
    });

    // По клику на фото - открываем галерею с предустановленным фото.
    const itemsGallery = this.galleryGridEl.querySelectorAll('[data-item-gallery]');
    if (itemsGallery && itemsGallery.length) {
      itemsGallery.forEach((el, index) => {
        el.addEventListener('click', () => {
          // Проверка нужна на всякий случай, если вдруг длина массивов будет разная
          if (list.length >= itemsGallery.length) {
            this.galleryPopupPlugin.openGallery(index);
          } else {
            this.galleryPopupPlugin.openGallery(0);
          }
        });
      });
    }
  }
}

const galleryMain = document.querySelector('[data-gallery="main"]');

if (galleryMain) {
  (async () => {
    const gallery = new Gallery('[data-gallery="main"]');
    await gallery.init();
  })();
}
