import Chart from 'chart.js/auto';

const DONUT_CHART_CONFIG = {
  type    : 'doughnut',
  options : {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    responsive : true,
    cutout     : '89%'
  }
};

class DonutChart {
  constructor(el) {
    this.chartEl = el;
    this.chartCanvas = this.chartEl.querySelector('[data-donut-chart="canvas"]');
    this.datalist = [];
  }

  init() {
    if (!this.chartEl.dataset || !this.chartEl.dataset.datalist) {
      return;
    }

    this.datalist = this.parseData(this.chartEl.dataset.datalist);

    if (!this.datalist) {
      return;
    }

    const formattedData = this.formatChartData();
    this.initChart(formattedData);
  }

  parseData(datalist) {
    try {
      return JSON.parse(datalist);
    } catch (e) {
      return null;
    }
  }

  formatChartData() {
    const backgroundColor = this.datalist.reduce((acc, el) => ([...acc, el.color]), []);
    const count = this.datalist.reduce((acc, el) => ([...acc, Math.round(+el.count)]), []);
    return {
      datasets: [{
        backgroundColor,
        data        : [...count],
        borderColor : 'transparent',
        borderWidth : 0
      }]
    };
  }

  initChart(data) {
    new Chart(this.chartCanvas, {
      ...DONUT_CHART_CONFIG,
      data
    });
  }
}

const donutChartElements = [...document.querySelectorAll('[data-donut-chart="main"]')];
donutChartElements.forEach((chartEl) => {
  const chart = new DonutChart(chartEl);
  chart.init();
});
