import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const baseCardsSliders = document.querySelectorAll('[data-base-card-slider]');

const baseCardsSliderOptions = {
  loop          : false,
  slidesPerView : 1,
  spaceBetween  : 16,
  navigation    : {
    nextEl : '[data-base-card-slider-next]',
    prevEl : '[data-base-card-slider-prev]'
  },
  breakpoints: {
    // when window width is >= 768
    768: {
      slidesPerView : 2,
      spaceBetween  : 20
    },
    // when window width is >= 1024
    1024: {
      slidesPerView : 3,
      spaceBetween  : 30
    }
  }
};

(function initBaseCardsSliders() {
  baseCardsSliders.forEach((slider) => new Swiper(slider, baseCardsSliderOptions));
}());
