class DesktopMenu {
  constructor() {
    this.listItemMenu = document.querySelectorAll('[data-item-main-menu]');
    this.clearTimeoutMenu = null;
    this.timeoutOpenMenu = 300;
  }

  init() {
    if (this.listItemMenu && this.listItemMenu.length) {
      this.listItemMenu.forEach((item) => {
        item.addEventListener('mouseenter', () => {
          this.updateOpenMenu(item);
        });

        item.addEventListener('mouseleave', () => {
          this.updateOpenMenu();
        });
      });
    }
  }

  updateOpenMenu(itemMenu) {
    const _this = this;
    clearTimeout(this.clearTimeoutMenu);

    this.clearTimeoutMenu = setTimeout(() => {
      _this.listItemMenu.forEach((el) => {
        el.classList.remove('open');
      });

      if (itemMenu) {
        itemMenu.classList.add('open');
      }
    }, this.timeoutOpenMenu);
  }
}

const desktopMenu = new DesktopMenu();
desktopMenu.init();
