import $ from 'jquery';
import 'select2';
import SimpleBar from 'simplebar';
import datepicker from 'js-datepicker';

/**
 * The name of the get-parameter responsible for the page number
 * Required to reset the page number
 */
const PAGE_GET_PARAM_NAME = 'company_paper_list';
const lang = document.querySelector('html').getAttribute('lang');

const paramsDatePicker = {
  ru: {
    disableYearOverlay : true,
    customDays         : ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
    customMonths       : ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    customMonthsName   : ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
  },
  en: {
    disableYearOverlay : true,
    customDays         : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    customMonths       : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    customMonthsName   : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  }
};

class EntriesFilters {
  constructor(selector, options) {
    this.list = document.querySelectorAll(selector);
    this.options = options;
  }

  init() {
    this.list.forEach((selectWrapper) => {
      const selectEl = selectWrapper.querySelector('select');

      $(selectEl)
        .select2({
          dropdownParent: $(selectWrapper),
          ...this.options
        })
        .on('select2:open', this.openHandler)
        .on('select2:closing', this.closingHandler)
        .on('select2:select', this.selectHandler.bind(this));
    });

    this.initDatepicker();
  }

  getParamsDatepicker(nameParam, objText, startDate, minDate, maxDate) {
    return {
      minDate,
      maxDate,
      startDate,
      dateSelected : startDate,
      onShow       : (instance) => {
        if (startDate) {
          instance.navigate(startDate);
        }
      },
      formatter: (input, date) => {
        this.setDateForDatepicker(input, date, objText);
      },
      onSelect: (instance) => {
        const year = instance.dateSelected.getFullYear();
        let month = instance.dateSelected.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let day = instance.dateSelected.getDate();
        if (day < 10) day = `0${day}`;

        this.applyFilter({
          param : nameParam,
          value : `${year}-${month}-${day}`
        });
      }
    };
  }

  setDateForDatepicker(obj, date, objText) {
    const month = paramsDatePicker[lang].customMonthsName[date.getMonth()];
    const year = date.getFullYear();
    let day = date.getDate();
    if (day < 10) day = `0${day}`;

    // eslint-disable-next-line no-param-reassign
    obj.value = `${day} ${month} ${year}`;
    if (objText) {
      // eslint-disable-next-line no-param-reassign
      objText.innerHTML = `${day} ${month} ${year}`;
    }
  }

  initDatepicker() {
    const $datepickerFrom = document.querySelector('[data-date-from-datepicker]');
    const $datepickerTo = document.querySelector('[data-date-to-datepicker]');
    if (!$datepickerFrom || !$datepickerTo) return;

    const $textDatepickerFrom = document.querySelector('[data-text-date-from-datepicker]');
    const $textDatepickerTo = document.querySelector('[data-text-date-to-datepicker]');
    const initDateFrom = $datepickerFrom.getAttribute('data-date-from-datepicker')
      ? new Date($datepickerFrom.getAttribute('data-date-from-datepicker')) : null;
    const initDateTo = $datepickerTo.getAttribute('data-date-to-datepicker')
      ? new Date($datepickerTo.getAttribute('data-date-to-datepicker')) : null;

    // Заполняем первоначальными данными инпуты
    if (initDateFrom) this.setDateForDatepicker($datepickerFrom, initDateFrom, $textDatepickerFrom);
    if (initDateTo) this.setDateForDatepicker($datepickerTo, initDateTo, $textDatepickerTo);

    // Инициализируем datepicker
    const paramsFrom = this.getParamsDatepicker('dateFrom', $textDatepickerFrom, initDateFrom, null, initDateTo);
    const paramsTo = this.getParamsDatepicker('dateTo', $textDatepickerTo, initDateTo, initDateFrom, null);

    datepicker('[data-date-from-datepicker]', { ...paramsDatePicker[lang], ...paramsFrom });
    datepicker('[data-date-to-datepicker]', { ...paramsDatePicker[lang], ...paramsTo });

    document.querySelector('[data-clear-date-from-datepicker]').addEventListener('click', () => {
      this.applyFilter({
        param : 'dateFrom',
        value : null
      });
    });

    document.querySelector('[data-clear-date-to-datepicker]').addEventListener('click', () => {
      this.applyFilter({
        param : 'dateTo',
        value : null
      });
    });
  }

  applyFilter({ param, value }) {
    if (!param) return;
    const url = new URL(window.location);
    const { searchParams } = url;
    searchParams.delete(PAGE_GET_PARAM_NAME);
    searchParams.delete('pagination');

    const nameParamPage = this.getNameSearchParamPage(searchParams);
    if (nameParamPage) {
      searchParams.delete(nameParamPage);
    }

    if (!value) {
      searchParams.delete(param);
    } else {
      searchParams.set(param, value);
    }
    window.location.href = url.toString();
  }

  getNameSearchParamPage(searchParams) {
    let nameParamPage = null;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of searchParams) {
      if (!item || (item && !item.length)) break;

      const [nameParam] = item;
      if (nameParam && nameParam.indexOf('PAGEN_') > -1) {
        nameParamPage = nameParam;
      }
    }

    return nameParamPage;
  }

  selectHandler(e) {
    const { target } = e;

    this.applyFilter({
      param : target.getAttribute('name'),
      value : target.value
    });
  }

  closingHandler(e) {
    const { target } = e;
    const selectBlock = target.parentNode.parentNode;
    const selectResultsElements = selectBlock.querySelector('.select2-results');
    selectResultsElements.classList.remove('select2-results--visible');
  }

  openHandler(e) {
    const { target } = e;
    const selectBlock = target.parentNode.parentNode;
    const selectResultsElements = selectBlock.querySelector('.select2-results');
    if (target.length > 5) new SimpleBar(selectResultsElements);
    selectResultsElements.classList.add('select2-results--visible');
  }
}

const filters = new EntriesFilters('[data-select-filter]', {
  minimumResultsForSearch : -1,
  dropdownAutoWidth       : true,
  width                   : '100%'
});

filters.init();
