import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersProjects = document.querySelectorAll('[data-swiper-projects]');
const sliderProjectOptions = {
  loop          : false,
  slidesPerView : 1,
  spaceBetween  : 16,
  navigation    : {
    nextEl : '[data-swiper-projects-next]',
    prevEl : '[data-swiper-projects-prev]'
  },
  pagination: {
    el        : '[data-swiper-projects-pag]',
    clickable : true
  },
  breakpoints: {
    1200: {
      spaceBetween: 32
    }
  }
};

(function initProjectSlider() {
  slidersProjects.forEach((slider) => new Swiper(slider, sliderProjectOptions));
}());
