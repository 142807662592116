class SearchControl {
  constructor() {
    this.searchControlInputItems = [...document.querySelectorAll('[data-search="input"]')];
    this.searchControlClearItems = [...document.querySelectorAll('[data-search="clear-button"]')];
  }

  init() {
    this.bindHandlers();
  }

  bindHandlers() {
    this.searchControlInputItems.forEach((el) => el.addEventListener('input', this.inputHandler.bind(this)));
    this.searchControlClearItems.forEach((el) => el.addEventListener('click', this.clearButtonHandler.bind(this)));
  }

  inputHandler(e) {
    const { target, target : { value } } = e;
    const currentControl = target.closest('[data-search="control"]');
    const currentClearButton = currentControl.querySelector('[data-search="clear-button"]');
    const methodType = (!value.trim().length) ? 'add' : 'remove';
    currentClearButton.classList[methodType]('search-control__button--hidden');
  }

  clearButtonHandler(e) {
    const { currentTarget } = e;
    const currentControl = currentTarget.closest('[data-search="control"]');
    const currentInput = currentControl.querySelector('[data-search="input"]');
    currentTarget.classList.add('search-control__button--hidden');
    currentInput.value = '';
  }
}

const searchControl = new SearchControl();
searchControl.init();
