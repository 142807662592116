// Slider
const projectsSlider = document.querySelector('[data-about-slider]');
if (projectsSlider) {
  const projectsSliderObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }

      projectsSliderObserver.disconnect();
      import(/* webpackChunkName: "main-projects-slider" */ './history/index');
    });
  });

  projectsSliderObserver.observe(projectsSlider);
}
