import { debounce } from 'lodash';

const accordions = document.querySelectorAll('.js-accordion');

accordions.forEach((accordion) => {
  const header = accordion.querySelector('.js-accordion-name');
  const container = accordion.querySelector('.js-accordion-content');
  const debouncedHandler = debounce(setCurrentHeight, 100);

  setCurrentHeight(accordion);

  accordion.addEventListener('click', () => {
    if (accordion.classList.contains('is-active')) {
      accordion.classList.toggle('is-active');
    } else {
      accordions.forEach((item) => { // eslint-disable-line
        item.classList.remove('is-active');
        setCurrentHeight(item);
      });

      accordion.classList.add('is-active');
    }

    setCurrentHeight(accordion);
  });

  window.addEventListener('resize', () => debouncedHandler(accordion));

  function setCurrentHeight(item) {
    if (item.classList.contains('is-active')) {
      item.style.height = `${container.scrollHeight + header.scrollHeight + 20}px`; // eslint-disable-line
    } else {
      item.style.height = `${header.scrollHeight}px`; // eslint-disable-line
    }
  }
});
