import './utils/styles';
import './utils/sprite';
import './utils/viewport-dimensions';

import './components/tabs/tabs-assets';
import './components/sliders/slider-features';
import './components/sliders/slider-projects';
import './components/sliders/slider-financial-reporting';
import './components/sliders/slider-company-newspaper';
import './components/sliders/slider-videos';
import './components/sliders/slider-gallery';
import './components/sliders/slider-one-full-width-el';
import './components/sliders/base-card-slider';
import './components/sliders/slider-main-banner';

import './pages/production/map';
import './pages/contacts/map';
import './pages/esg/map';

import './components/filters/filters';
import './components/accordion/accordion';

import './components/site-header/index';
import './components/video/video-prewview';
import './components/search/search-control';

import './components/gallery/index';

// About Page
import './pages/about/index';

import './components/donut-chart/index';

const vacancyApp = document.querySelector('#vacancy-app');

if (vacancyApp) {
  import(
    /* webpackChunkName: "vacancy-app" */
    './pages/about/vacancy/index'
  ).then((partial) => {
    partial.default(vacancyApp);
  });
}
