/* global YT */
const __YOUTUBE_IFRAME_API_SCRIPT_TAG_ID = 'youtube-iframe-api-script-tag';

class VideoPreview {
  constructor() {
    this.videoCardPreviewItems = [...document.querySelectorAll('[data-card-video="video-preview"]')];
  }

  init() {
    if (this.videoCardPreviewItems.length) {
      this.initVideoApi();
      this.bindHandlers();
    }
  }

  bindHandlers() {
    this.videoCardPreviewItems.forEach((el) => el.addEventListener('mouseenter', this.cardHandlerOnHover.call(this, el)));
    this.videoCardPreviewItems.forEach((el) => el.addEventListener('touchstart', this.cardHandlerOnHover.call(this, el)));
    this.videoCardPreviewItems.forEach((el) => el.addEventListener('mouseleave', this.cardHandlerOnHoverOut.call(this, el)));
    this.videoCardPreviewItems.forEach((el) => el.addEventListener('touchend', this.cardHandlerOnHoverOut.call(this, el)));
  }

  cardHandlerOnHover(el) {
    return () => {
      const videoEmbed = el.querySelector('[data-card-video="video-embed"]');
      const videoId = videoEmbed.getAttribute('data-card-video-id') || null;
      const playerBox = document.createElement('div');
      if (!this.isScriptLoaded()) {
        return;
      }
      videoEmbed.appendChild(playerBox);
      this.createPlayer(playerBox, videoId);
      videoEmbed.classList.add('video-preview__video-embed--active');
    };
  }

  createPlayer(playerEl, videoId) {
    new YT.Player(playerEl, {
      videoId,
      events: {
        onReady: (e) => e.target.playVideo()
      },
      playerVars: {
        controls       : 0,
        fs             : 0,
        modestbranding : 1,
        mute           : 1,
        loop           : 1,
        disablekb      : 1,
        showinfo       : 0,
        playsinline    : 1,
        iv_load_policy : 3,
        enablejsapi    : 1
      }
    });
  }

  cardHandlerOnHoverOut(el) {
    return () => {
      const videoEmbed = el.querySelector('[data-card-video="video-embed"]');
      videoEmbed.innerHTML = '';
      videoEmbed.classList.remove('video-preview__video-embed--active');
    };
  }

  isScriptLoaded() {
    return 'YT' in window;
  }

  initVideoApi() {
    if (this.isScriptLoaded()) {
      return;
    }

    const tag = document.createElement('script');
    tag.id = __YOUTUBE_IFRAME_API_SCRIPT_TAG_ID;
    tag.src = 'https://www.youtube.com/iframe_api';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}

window.videoPreview = new VideoPreview();
window.videoPreview.init();
