const { ymaps } = window;

if (document.querySelector('#esg-map')) {
  ymaps.ready(init); // eslint-disable-line
}

function setPolygon(result, objectManager, regionsGeocodes, map) {
  // Создадим объект regions, где ключи это ISO код региона.
  const regions = result.features.reduce((acc, feature) => {
    // Добавим ISO код региона в качестве feature.id для objectManager.
    const iso = feature.properties.iso3166;

    feature.id = iso; // eslint-disable-line
    // Добавим опции региона по умолчанию.
    feature.options = { // eslint-disable-line
      fillOpacity : 0,
      strokeColor : '#ffffff00'
    };

    // код региона
    regionsGeocodes.data.items.forEach((reg) => { // eslint-disable-line
      if (reg.osmId === iso) {
        feature.options.fillColor = '#0284c729'; // eslint-disable-line
        feature.options.fillOpacity = '1'; // eslint-disable-line
        feature.options.strokeColor = '#0284C7'; // eslint-disable-line
      }
    });

    acc[iso] = feature; // eslint-disable-line
    return acc;
  }, {});

  // Добавим регионы на карту.
  result.features = []; // eslint-disable-line
  Object.values(regions).forEach((region) => {
    result.features.push(region);
  });
  // Чтобы задать опции одиночным объектам и кластерам,
  // обратимся к дочерним коллекциям ObjectManager.
  objectManager.objects.options.set('preset', 'islands#greenDotIcon');
  objectManager.clusters.options.set('preset', 'islands#greenClusterIcons');

  objectManager.add(result);
  map.geoObjects.add(objectManager);
}

function init() {
  const language = document.querySelector('html').getAttribute('lang');
  const map = new ymaps.Map('esg-map', { // eslint-disable-line
      center   : [60.938872573521266, 69.12532819091788],
      zoom     : 5,
      controls : ['zoomControl']
    }, {
      searchControlProvider: 'yandex#search'
    }),
    // Создание иконки кластера
    MyClusterIconContentLayout = ymaps.templateLayoutFactory.createClass(
      `<div class="production-map__cluster-icon">
        {{ properties.geoObjects.length }}
      </div>`
    ),

    // Создание иконки объекта
    MyIconContentLayout = ymaps.templateLayoutFactory.createClass(// eslint-disable-line
      '<div class="production-map__icon" {% if properties.iconColor %}style="background-color: #$[properties.iconColor];"{% endif %}></div>'
    ),

    // Создание вложенного макета содержимого балуна.
    MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass( // eslint-disable-line
      `<a href="$[properties.balloonLink]" class="production-map__name">
            <span class="production-map__name-text">$[properties.balloonHeader]</span>
          </a>`
    ),
    geoObjects = [];

  // Запрещаем зум карты по скроллу
  map.behaviors.disable('scrollZoom');

  // Задаём параметры для кластера
  const clusterer = new ymaps.Clusterer({
    // Зададим макет метки кластера.
    clusterIconLayout : MyClusterIconContentLayout,
    // Чтобы метка была кликабельной, переопределим ее активную область.
    clusterIconShape  : {
      type        : 'Rectangle',
      coordinates : [[-16, -2], [10, 24]]
    },
    groupByCoordinates             : false,
    clusterDisableClickZoom        : true,
    clusterHideIconOnBalloonOpen   : false,
    geoObjectHideIconOnBalloonOpen : false,
    balloonContentLayoutHeight     : 120,
    clusterBalloonContentLayout    : 'cluster#balloonAccordion'
  });

  let enterprisePosition;
  let regionsGeocodes;

  (async () => {
    try {
      // Получаем список точек
      const response = await fetch(`/api/v1/esg/directions?locale=${language}`);
      enterprisePosition = await response.json();
    } catch (e) {
      console.log('Error: ', e);
    }

    if (enterprisePosition) {
      enterprisePosition.data.items.forEach((item, index) => {
        geoObjects[index] = new ymaps.Placemark([item.point.geo.lat, item.point.geo.lon], { // eslint-disable-line
          iconColor            : item.point.color,
          balloonContentHeader : `<a href="${item.detailPageUrl}" class="production-map__cluster-item">
                                    <span class="production-map__cluster-item-text">${item.name}</span>
                                </a>`,
          balloonHeader : item.name,
          balloonLink   : item.detailPageUrl
        }, {
          balloonShadow          : false,
          balloonContentLayout   : MyBalloonContentLayout,
          balloonPanelMaxMapArea : 0,
          balloonOffset          : [3, -6],
          balloonMinWidth        : 138,
          balloonMaxWidth        : 223,

          iconContentLayout     : MyIconContentLayout,
          iconLayout            : 'default#imageWithContent',
          iconImageHref         : '',
          iconImageSize         : [20, 20],
          iconImageOffset       : [-10, -10],
          iconContentOffset     : [5, 5],
          hideIconOnBalloonOpen : false
        });
      });
    }

    try {
      // Получаем список регионов и стран
      const response = await fetch(`/api/v1/esg/directions/regions?locale=${language}`);
      regionsGeocodes = await response.json();
    } catch (e) {
      console.log('Error: ', e);
    }

    const objectManager = new ymaps.ObjectManager(); // eslint-disable-line
    // Загружаем регионы России
    ymaps.borders.load('RU', { // eslint-disable-line
      lang    : language,
      quality : 2
    }).then(async (result) => {
      if (regionsGeocodes) {
        setPolygon(result, objectManager, regionsGeocodes, map);
      }

      // Добавим коллекцию на карту.
      clusterer.add(geoObjects);
      map.geoObjects.add(clusterer);
      // Центрируем карту на все точки
      map.setBounds(clusterer.getBounds(), { checkZoomRange: true });
    });

    // Загружаем страны
    ymaps.borders.load('001', { // eslint-disable-line
      lang    : language,
      quality : 2
    }).then(async (result) => {
      if (regionsGeocodes) {
        setPolygon(result, objectManager, regionsGeocodes, map);
      }
    });
  })();
}
