import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersFullWidth = document.querySelectorAll('[data-swiper-full-width]');
const slidersFullWidthOptions = {
  loop          : false,
  slidesPerView : 1,
  spaceBetween  : 16,
  navigation    : {
    nextEl : '[data-swiper-full-width-next]',
    prevEl : '[data-swiper-full-width-prev]'
  },
  pagination: {
    el        : '[data-swiper-full-width-pag]',
    clickable : true
  }
};

(function initFullWidthSlider() {
  slidersFullWidth.forEach((slider) => new Swiper(slider, slidersFullWidthOptions));
}());
