import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersGallery = document.querySelectorAll('[data-swiper-gallery]');
const sliderGalleryOptions = {
  loop          : false,
  slidesPerView : 1,
  spaceBetween  : 16,
  navigation    : {
    nextEl : '[data-swiper-gallery-next]',
    prevEl : '[data-swiper-gallery-prev]'
  },
  pagination: {
    el        : '[data-swiper-gallery-pag]',
    clickable : true
  },
  breakpoints: {
    576: {
      slidesPerView : 1.5,
      spaceBetween  : 16
    },
    1024: {
      slidesPerView : 2.5,
      spaceBetween  : 32
    },
    1200: {
      slidesPerView : 3,
      spaceBetween  : 32
    }
  }
};

(function initGallerySlider() {
  slidersGallery.forEach((slider) => new Swiper(slider, sliderGalleryOptions));
}());
