import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersFinancialReporting = document.querySelectorAll('[data-swiper-financial-reporting]');
const sliderFinancialReportingOptions = {
  loop          : false,
  slidesPerView : 1.45,
  spaceBetween  : 16,
  breakpoints   : {
    1024: {
      slidesPerView : 2,
      spaceBetween  : 32
    }
  }
};

(function initFinancialReportingSlider() {
  slidersFinancialReporting.forEach((slider) => new Swiper(slider, sliderFinancialReportingOptions));
}());
