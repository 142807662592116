import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const slidersFeatures = document.querySelectorAll('[data-swiper-features]');
const sliderFeaturesOptions = {
  loop          : false,
  slidesPerView : 1.45,
  spaceBetween  : 16,
  navigation    : {
    nextEl : '[data-swiper-features-next]',
    prevEl : '[data-swiper-features-prev]'
  },
  pagination: {
    el        : '[data-swiper-features-pag]',
    clickable : true
  },
  breakpoints: {
    1024: {
      slidesPerView : 3,
      spaceBetween  : 32
    },
    1200: {
      slidesPerView : 4,
      spaceBetween  : 32
    }
  }
};

(function initFeatureSlider() {
  slidersFeatures.forEach((slider) => new Swiper(slider, sliderFeaturesOptions));
}());
